$menu-slider-width: 220px;
$dashboard-header-height: 64px;

$menu-slider-width-ss: 200px;
$menu-slider-width-xs: 0px;

$dashboard-header-height-ss: 54px;
$dashboard-header-height-xs: 54px;

// colors 
$primary-color: #1677ff;
$bit-light-primary-color: #4a96ff;
$light-primary-color: #d2e5ff;
$very-light-primary-color: #e7f1ff;

$gray: #4f4f4f;
$light-gray: #f5f5f5;
$very-light-gray: #fafafa;