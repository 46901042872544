@import '../../../../../theme/variables.scss';

.dashboard-content {
    width: calc(100% - #{$menu-slider-width} - 32px);
    margin-top: $dashboard-header-height;
    margin-right: 16px;
    margin-left: calc(#{$menu-slider-width} + 16px);

    .site-layout-background {
        padding: 10px 24px;
        min-height: calc(100vh - #{$dashboard-header-height * 2});

        @media (max-width: 1280px) {
            margin-top: $dashboard-header-height-ss;
            margin: 0 !important;
        }
    }

    @media (max-width: 1280px) {
        width: calc(100% - #{$menu-slider-width-ss} - 32px);
        margin-top: $dashboard-header-height-ss;
        margin-left: calc(#{$menu-slider-width-ss} + 16px);
    }

    @media (max-width: 768px) {
        width: 100%;
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-top: $dashboard-header-height-xs + 10px;
    }
}