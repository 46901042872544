.edit-course {
    width: 100%;

    .edit-header {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    .edit-body {
        display: flex;
        gap: 50px;

        .edit-left {
            width: 50%;

            .thumbnail {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .edit-right {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 20px;


            .top {
                display: flex;
                justify-content: space-between;
                align-items: start;

                h2 {
                    font-size: 1.5rem;
                    font-weight: 600;
                    margin: 0;
                }
            }

            .content {

                .ant-list {
                    border: none;

                    .ant-list-item {
                        padding: 20px 30px;
                        border: none;
                        background-color: #fff;
                        margin-bottom: 20px;
                        border-radius: 10px;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

                        display: flex;
                        justify-content: space-between;
                        align-items: start;

                        .no-edit {
                            section {
                                display: flex;
                                align-items: center;
                                margin: 0;
                                padding: 0;
                                line-height: 1.2;

                                p {
                                    width: 100px;
                                    font-weight: 600;
                                }

                                span {
                                    color: #656565;
                                    width: 100%;
                                }
                            }

                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}