.user-overview {
    h1 {
        font-size: 1.2rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }

    .user-overview__basic-info {

        .details {
            display: flex;
            justify-content: start;
            align-items: start;
            gap: 10em;

            @media (max-width: 1000px) {
                flex-direction: column;
                gap: 0;
            }

            section {
                div {
                    display: flex;
                    gap: 1em;

                    h4 {
                        width: 150px;
                    }

                    @media (max-width: 600px) {
                        gap: 0.5em;

                        h4 {
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}