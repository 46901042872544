.adim-wishlist-page {
    .wishlist-list {
        .wishlist-item {
            width: 100%;
            padding: 20px;
            background-color: white;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            margin-bottom: 20px;

            h4 {
                font-size: 1rem;
                font-weight: 500;
                margin: 0;
                line-height: 1.5;
            }

            p {
                font-size: 0.9rem;
                margin: 4px 0;
                line-height: 1.5;
                color: rgb(73, 73, 73);
            }

            .student-data {
                margin-bottom: 20px;

            }

            .course-data {
                margin-left: 20px;

                h4 {
                    margin-bottom: 10px;
                }

                .course {
                    background-color: #f0f8ff;
                    padding: 5px 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}