.user-cources {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
            font-size: 1.5rem;
            margin: 0;
            line-height: 0;
        }
    }

    .cources {
        margin-top: 20px;

        .cource-item {
            border: 1px solid #e0e0e0;
            border-radius: 10px;
            transition: all ease-in-out 0.3s;
            margin-bottom: 20px;

            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                transition: all ease-in-out 0.3s;
            }
        }
    }
}