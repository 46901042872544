.wishlist-page {
    .ant-list {
        width: 100%;
        padding: 0 5px;

        .course-card {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 40px;
            transition: all 0.5s ease-in-out;
            cursor: pointer;

            @media (max-width: 1280px) {
                height: 100%;
            }

            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                transition: all 0.5s ease-in-out;
            }

            .card-image-container {
                position: relative;
                overflow: hidden;

                .cource-card-header-buttons {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    display: flex;
                    justify-content: space-between;
                    width: 98%;

                    .duration {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 62px;
                        background: #fff;
                        gap: 10px;
                        padding: 10px;
                        border-top-right-radius: 50px;
                        border-bottom-right-radius: 50px;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

                        .ant-btn {
                            background-color: #ff7da8;
                            color: #fff;
                            border: none;
                            border-radius: 50px;
                            padding: 10px;
                            height: auto;
                            font-size: 1.4rem;
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            transition: all 0.3s ease-in-out;

                            &:hover {
                                background-color: #7257c9;
                                color: #fff;
                                transition: all 0.3s ease-in-out;
                            }
                        }
                    }

                    .btns {
                        display: flex;
                        gap: 10px;
                    }
                }

                .course-visiblity {
                    position: absolute;
                    top: 10px;
                    right: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    height: 32px;
                    gap: 10px;
                    border-radius: 50px;
                    padding: 5px 10px;
                    font-size: 0.8rem;
                }

                img {
                    display: block;
                    width: 100%;
                    object-fit: cover;
                    transition: all 0.5s ease-in-out;

                    &:hover {
                        transform: scale(1.1);
                        transition: all 0.5s ease-in-out;
                    }
                }
            }

            .card-body {
                padding: 10px;
                gap: 10px;

                .card-description {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .price {
                        p {
                            margin: 5px 0;
                            font-size: 1.2rem;
                            font-weight: 500;
                        }

                        .free-text {
                            color: #005522;
                            background-color: #a5ffd5;
                            padding: 0px 20px;
                            border-radius: 50px;
                        }
                    }

                    .author {
                        display: flex;
                        gap: 5px;
                        align-items: center;

                        img {
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                    }

                    h3 {
                        font-size: 0.9rem;
                        margin: 0;
                        font-weight: 500;
                        line-height: 1;
                        color: rgb(73, 73, 73);
                    }
                }

                .title {
                    font-size: 1.1rem;
                    font-weight: 500;
                    margin: 10px 0;
                    color: rgb(37, 37, 37);
                }
            }

            .card-footer {
                width: 100%;
                padding: 10px;

                .button-continer {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    gap: 5px;

                    .enroll-now {
                        width: 100%;
                        // padding: 0 10px;

                        .ant-btn {
                            width: 100%;
                            border: #1677ff solid 1px;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }
}