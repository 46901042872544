.landing-page {
    header {
        position: fixed;
        width: 100vw;
        background-color: #fff;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        z-index: 1000;

        .header-conteiner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 200px;

            @media (max-width: 1285px) {
                padding: 10px 100px;
            }

            @media (max-width: 800px) {
                padding: 10px 50px;
            }

            .logo {
                width: 150px;

                img {
                    width: 100%;
                }

                @media (max-width: 768px) {
                    width: 100px;
                }
            }

            .btns {
                display: flex;
                width: 300px;
                justify-content: space-between;

                @media (max-width: 768px) {
                    width: auto;
                }

                .ant-btn {
                    width: 100px;
                    height: 40px;
                    font-size: 16px;
                    font-weight: 500;
                    border-radius: 100px;
                }

                .signup {
                    @media (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }
    }

    .body {
        .hero {
            width: 76vw;
            height: 80vh;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            @media (max-width: 1280px) {
                padding-top: 5em;
                height: 60vh;
            }

            @media (max-width: 975px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-top: 10em;
                height: 100%;
            }

            @media (max-width: 700px) {
                padding-top: 5em;
                width: 90vw;
            }

            .content {
                width: 40%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @media (max-width: 975px) {
                    width: 100%;
                    height: 50%;
                    align-items: center;
                    text-align: center;
                }

                h2 {
                    font-size: 30px;
                    font-weight: 500;
                    color: #000;
                    line-height: 0;
                    margin-bottom: 20px;

                    @media (max-width: 975px) {
                        font-size: 2rem;
                    }
                }

                h1 {
                    font-size: 4rem;
                    font-weight: 700;
                    color: #1677ff;
                    padding: 0;
                    margin: 0;

                    @media (max-width: 975px) {
                        font-size: 2.5rem;
                        margin-top: 10px;
                    }
                }

                p {
                    margin-top: 20px;
                    font-size: 20px;
                    font-weight: 400;
                    color: #000;
                }

                .buttons {
                    margin-top: 40px;
                    display: flex;

                    @media (max-width: 975px) {
                        margin-top: 20px;
                        flex-direction: column;
                        gap: 10px;
                    }

                    .ant-btn {
                        width: 160px;
                        height: 50px;
                        font-size: 16px;
                        font-weight: 500;
                        border-radius: 100px;
                    }
                }
            }

            .image {
                width: 60%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 975px) {
                    width: 100%;
                    height: 50%;
                }

                img {
                    width: 100%;
                }
            }
        }

        .about {
            width: 76vw;
            margin: 0 auto;
            margin-top: 80px;

            @media (max-width: 768px) {
                width: 90vw;
                margin-top: 40px;
            }

            h1 {
                font-size: 2.5rem;
                font-weight: 700;
                color: #292929;
                margin-bottom: 50px;

                @media (max-width: 768px) {
                    font-size: 2rem;
                    margin: 0;
                }
            }

            p {
                font-size: 20px;
                font-weight: 400;
                color: #424242;
                line-height: 1.5;
                margin-bottom: 20px;

                @media (max-width: 768px) {
                    font-size: 16px;
                }
            }
        }

        .carousel-section {
            width: 76vw;
            margin: 0 auto;
            margin-top: 60px;

            @media (max-width: 768px) {
                width: 90vw;
                margin-top: 30px;
            }

            div {
                img {
                    width: 100%;
                }
            }
        }

        .courses {
            width: 76vw;
            margin: 0 auto;
            margin-top: 70px;

            @media (max-width: 768px) {
                width: 90vw;
                margin-top: 30px;
            }

            h1 {
                font-size: 2.5rem;
                font-weight: 700;
                color: #292929;
                margin-bottom: 50px;
                margin: 0 0 10px 0;

                @media (max-width: 768px) {
                    font-size: 2rem;
                }
            }

            .cources-body {
                .ant-list {
                    width: 100%;
                    padding: 0 5px;

                    .list-card {
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                        border-radius: 10px;
                        overflow: hidden;
                        margin-bottom: 20px;
                        transition: all 0.5s ease-in-out;
                        height: 250px;
                        padding: 10px 20px;

                        @media (max-width: 1440px) {
                            height: 270px;
                        }

                        @media (max-width: 1366px) {
                            height: 290px;
                        }

                        @media (max-width: 1240px) {
                            height: 310px;
                        }

                        @media (max-width: 768px) {
                            height: 100%;
                        }
                    }

                    .course-card {
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                        border-radius: 10px;
                        overflow: hidden;
                        margin-bottom: 40px;
                        transition: all 0.5s ease-in-out;
                        // height: 400px;

                        @media (max-width: 1280px) {
                            height: 100%;
                        }

                        &:hover {
                            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                            transition: all 0.5s ease-in-out;
                            transform: scale(1.01);
                        }

                        .card-image-container {
                            position: relative;
                            overflow: hidden;

                            img {
                                display: block;
                                width: 100%;
                                // height: 220px;
                                object-fit: cover;
                                transition: all 0.5s ease-in-out;

                                &:hover {
                                    transform: scale(1.1);
                                    transition: all 0.5s ease-in-out;
                                }
                            }
                        }

                        .card-body {
                            padding: 10px;
                            gap: 10px;

                            .card-description {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                .price {
                                    p {
                                        margin: 5px 0;
                                        font-size: 1.2rem;
                                        font-weight: 500;
                                    }

                                    .free-text {
                                        color: #005522;
                                        background-color: #a5ffd5;
                                        padding: 0px 20px;
                                        border-radius: 50px;
                                    }
                                }

                                .author {
                                    display: flex;
                                    gap: 5px;
                                    align-items: center;

                                    img {
                                        width: 25px;
                                        height: 25px;
                                        border-radius: 50%;
                                        object-fit: cover;
                                    }
                                }

                                h3 {
                                    font-size: 0.9rem;
                                    margin: 0;
                                    font-weight: 500;
                                    line-height: 1;
                                    color: rgb(73, 73, 73);
                                }
                            }

                            .title {
                                font-size: 1.1rem;
                                font-weight: 500;
                                margin: 10px 0;
                                color: rgb(37, 37, 37);
                                height: 60px;
                            }
                        }

                        .card-footer {
                            padding: 10px;

                            .button-continer {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-bottom: 10px;
                                gap: 5px;


                                .view-btn {
                                    width: 100%;
                                    // padding: 0 10px;

                                    .ant-btn {
                                        width: 100%;
                                        border: #1677ff solid 1px;
                                        border-radius: 5px;
                                    }
                                }
                            }
                        }
                    }
                }

                .no-courcesdiv {
                    height: 200px;
                }
            }
        }

        .margin_low {
            margin-top: 20px;

            .para {
                font-size: 16px;
                font-weight: 400;
                color: #424242;
                line-height: 1.5;
                margin-bottom: 20px;
            }
        }
    }

    footer {
        margin-top: 70px;
        background-color: #ecf1ffaa;
        padding: 50px 12vw;
        gap: 200px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 1285px) {
            gap: 50px;
        }

        @media (max-width: 800px) {
            padding: 50px 50px;
            gap: 50px
        }

        @media (max-width: 768px) {
            padding: 50px 20px;
            flex-direction: column;
            text-align: center;
            gap: 20px;
            margin-top: 0px;
        }

        .logo {
            margin: 0;
            margin-bottom: 20px;
            width: 200px;

            @media (max-width: 800px) {
                margin: 0 auto 20px auto;
                width: 150px;
            }

            img {
                width: 100%;
            }
        }

        .links {
            margin: 0 auto;
            text-align: center;
            margin-bottom: 20px
        }

        div {
            width: 100%;

            .logo {
                a {
                    text-decoration: none;

                    img {
                        width: 150px;
                        display: block;
                        margin: 0;
                        padding: 0;
                    }

                    h1 {
                        color: #12006d;
                        font-size: 1.5rem;
                        font-weight: 700;
                        margin: 0;

                        h2 {
                            color: #5a729e;
                            font-weight: 400;
                            font-size: 16px;
                            letter-spacing: 12px;
                        }
                    }
                }
            }
        }

        .footer-sec {
            p {
                margin: 5px 0;
                line-height: 1.5;

                span {
                    margin-right: 10px
                }

                a {
                    text-decoration: none;
                    color: #000;
                    font-size: 1rem;
                    margin: 5px 0;

                    &:hover {
                        color: #12006d
                    }
                }
            }
        }
    }

    .copy-right {
        border-top: 1px solid #eaeaea;
        background-color: #ecf2ffaa;
        text-align: center;
        padding: 20px 300px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 1285px) {
            padding: 20px 100px;
        }

        @media (max-width: 800px) {
            padding: 20px 50px;
            flex-direction: column;
            gap: 10px;
        }

        p {
            font-size: 0.9rem;

            a {
                text-decoration: none;
                color: #4c577d;
                font-weight: 500;
                margin: 5px 0;

                &:hover {
                    color: #12006d
                }
            }
        }
    }
}