@import '../../../../../theme/variables.scss';

.user-profile-tooltip {
    .ant-btn {
        color: #5d5d5d !important;

        &:hover {
            color: #000 !important;
        }
    }
}

.dashboard-header {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
    position: fixed;
    width: calc(100% - #{$menu-slider-width});
    margin-left: #{$menu-slider-width};
    z-index: 100;
    height: $dashboard-header-height;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.033);

    @media (max-width: 1280px) {
        width: calc(100% - #{$menu-slider-width-ss});
        margin-left: #{$menu-slider-width-ss};
        height: $dashboard-header-height-ss;
    }

    @media (max-width: 768px) {
        width: 100%;
        margin-left: 0;
        height: $dashboard-header-height-xs;
    }

    .language-selector {
        width: 90px;
    }

    .profile-bar {
        display: flex;
        align-items: center;
        margin-right: 20px;
        gap: 5px;
        cursor: pointer;
        height: 50%;

        .profile-name {
            font-weight: 500;
            display: block;
            margin: 0;
            padding: 0;

            p {
                font-size: 1rem;
                margin-top: 20px;
                line-height: 0;
            }

            h5 {
                font-size: 0.9em;
                font-weight: 500;
                color: #9c9c9cc7;
                line-height: 0;
            }
        }
    }

    .notification-icon {
        font-size: 22px;
        color: #9373ff;

        &:hover {
            color: #673bf9;
        }
    }
}

.notification-container {
    width: 300px;
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px;

    .notification-item {
        display: flex;
        justify-content: space-between;
        padding: 10px 10px;
        margin-bottom: 5px;
        border-radius: 10px;
    }

    .empty-notification {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        p {
            font-size: 1.2em;
            font-weight: 300;
            color: #9c9c9cc7;
            width: 60%;
            text-align: center;
            margin: auto;
        }
    }
}