.drawer-header {
    margin: 0 0 20px 0;
    border: 1px solid #cbade9;
    padding: 10px 20px;
    border-radius: 10px;

    p {
        margin: 0 0 10px 0;
    }
}

.ql-tooltip {
    z-index: 9999;
    left: -0.67px !important;
}