.buy-course {
    .course-data {
        border: 1px solid #ccc;
        align-items: center;
        margin-bottom: 20px;
        padding: 20px;
        border-radius: 10px;

        h2 {
            font-size: 1.5rem;
            margin: 0;
        }

        p {
            margin: 20px 0;
            font-size: 1.2rem;
        }

        h3 {
            font-size: 1.5rem;
            margin: 0;
            color: #1677ff;
        }
    }

    .instruction {
        margin-top: 20px;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #ccc;
        background-color: #f5f5f5;

        h2 {
            font-size: 1.5rem;
            margin: 0;
        }

        p {
            font-size: 1rem;
            margin: 10px 0;
            margin-bottom: 20px;
            line-height: 1.5;
            color: #4b4b4b;
        }

        section {
            margin-top: 10px;
            line-height: 1.6;
            color: #000;

            span {
                font-weight: 600;
            }
        }
    }
}