.notes-page {
    .notes-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .notes-list {
        .note-item {
            display: flex;
            flex-direction: column;
            align-items: start;
            padding: 10px 20px;
            gap: 0;
            border: 1px solid #ccc;
            border-radius: 10px;
            margin-bottom: 10px;
            transition: all 0.3s;
            width: 100%;

            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                transition: all 0.3s;
            }

            .note-title {
                font-size: 18px;
                font-weight: 500;
            }

            .note-container {
                width: 100%;
                background-color: #e1efff;
                border-radius: 10px;

                .note-description {
                    padding: 0;
                    margin: 0;
                }
            }

            h3 {
                font-size: 16px;
                color: #494949;
                font-weight: 500;
                margin: 5px 0;

                span {
                    font-size: 14px;
                    color: #141414;
                    font-weight: 600;
                }
            }

            .note-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .note-actions {
                    display: flex;
                    align-items: center;

                    .note-action {
                        margin-left: 10px;
                        cursor: pointer;
                        transition: all 0.3s;

                        &:hover {
                            color: #007bff;
                        }
                    }
                }
            }
        }
    }
}