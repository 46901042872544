.home {
    .home-left-top {
        .bg-img {
            width: 100%;
            height: 200px;
            background-image: url('../../../Assets/Images/bg.jpg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 10px;
            overflow: hidden;
        }

        .profile-img {
            display: block;

            img {
                display: block;
                width: 150px;
                height: 150px;
                border-radius: 50%;
                background: #f5f5f5;
                padding: 8px;
                margin-top: -75px;
                margin-left: 20px;
            }

            @media (max-width: 768px) {
                img {
                    width: 120px;
                    height: 120px;
                    margin-top: -60px;
                    margin-left: 10px;
                }
            }
        }

        .profile-name {
            margin: 0;
            position: absolute;
            top: 280px;
            left: 430px;

            h1 {
                margin: 0;
                padding: 0;
            }

            h2 {
                margin: 0;
                padding: 0;
                font-weight: 400;
                color: #7e7e7e;
                font-size: 1.2rem;
            }

            @media (max-width: 1280px) {
                top: 265px;
                left: 410px;
            }

            @media (max-width: 768px) {
                top: 275px;
                left: 150px;

                h2 {
                    font-size: 0.8rem;
                }

                h1 {
                    font-size: 1.2rem;
                }
            }
        }
    }

    .home-continer {
        display: flex;
        gap: 40px;

        @media (max-width: 800px) {
            flex-direction: column;
            gap: 0;
        }

        .home-left {
            width: 60%;
            order: 1;

            @media (max-width: 800px) {
                width: 100%;
                order: 2;
                margin-top: 10px;
            }

            @media (max-width: 768px) {
                margin-top: 20px;
            }

            .updates-body {
                .sessions-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h1 {
                        margin: 20px 0;
                        padding: 0;
                        font-size: 1.5rem;
                        font-weight: 500;
                        color: #999999;

                        @media (max-width: 1280px) {
                            font-size: 1.2rem;
                            margin: 20px auto;
                        }

                        @media (max-width: 768px) {
                            margin: 5px auto 10px auto;
                        }
                    }
                }

                .ant-list {
                    .ant-list-item {
                        margin: 0 0 20px 0;
                        padding: 20px;
                        border: none;
                        background: #fff;
                        border-radius: 10px;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

                        .ant-divider {
                            margin: 1px 0;
                        }

                        .session-header {
                            align-items: start;



                            .actions {
                                display: flex;
                                align-items: start;
                                justify-content: center;
                                margin-bottom: 10px;
                                background-color: #ededed;
                                width: 180px;
                                padding: 10px 0;
                                border-radius: 10px;
                            }

                        }

                        h2 {
                            margin: 0;
                            padding: 0;
                            font-size: 0.9rem;
                            font-weight: 500;
                            color: #3d394c;
                            margin: 10px 0 10px 0;
                        }

                        .meeting-data {
                            display: flex;
                            justify-content: space-between;

                            @media (max-width: 600px) {
                                flex-direction: column;
                                gap: 10px;
                            }

                            .meet-title {
                                margin: 0;
                                padding: 0;
                                font-size: 1rem;
                                font-weight: 500;
                                color: #3d394c;
                            }

                            .meet-details {
                                font-weight: 400;

                                span {

                                    font-weight: 500;
                                    color: #3d394c;
                                }
                            }

                            .attendance {
                                span {
                                    color: #848484;
                                    font-weight: 400;
                                }

                                .attended {
                                    color: #009c34 !important;
                                }
                            }

                            .left {
                                width: 50%;

                                @media (max-width: 600px) {
                                    width: 100%;
                                }

                                .session-description {
                                    .ql-editor {
                                        margin: 0;
                                        padding: 0;
                                        font-size: 0.9rem;
                                    }
                                }

                                .meeting-link {
                                    .meetlink-continer {
                                        section {
                                            display: flex;
                                            align-items: center;
                                            gap: 20px;

                                            .join-link {
                                                color: #1677ff;
                                                border-color: #1677ff;

                                                &:hover {
                                                    color: #89baff;
                                                    border-color: #89baff;
                                                }
                                            }

                                            div {
                                                margin: 0;
                                                padding: 0;
                                                display: flex;
                                                align-items: center;
                                                gap: 5px;

                                                span {
                                                    color: #848484;
                                                }
                                            }
                                        }
                                    }
                                }

                                .meeting-date-time {
                                    margin: 0;
                                    padding: 0;

                                    .date-continer {
                                        margin: 10px 0;
                                        padding: 0;
                                        display: flex;
                                        gap: 20px;

                                        div {
                                            display: flex;
                                            align-items: center;
                                            gap: 5px;
                                            margin: 0;
                                            padding: 0;
                                            height: 30px;

                                            span {
                                                width: 15px;
                                            }
                                        }
                                    }
                                }
                            }

                            .right {
                                width: 300px;

                                img {
                                    width: 100%;
                                    border-radius: 10px;
                                }

                                @media (max-width: 1300px) {
                                    width: 200px;
                                }

                                @media (max-width: 600px) {
                                    width: 90%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .home-right {
            margin-top: 70px;
            width: 40%;
            order: 2;

            @media (max-width: 800px) {
                width: 100%;
                margin-top: 20px;
                order: 1;
            }

            @media (max-width: 768px) {
                margin-top: 0px;
            }

            .special-notes {
                background-color: #fff;
                min-height: 400px;
                border-radius: 10px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                padding: 10px 20px;
                position: relative;

                .title {
                    margin: 0;
                    padding: 0;
                    font-size: 1.2rem;
                    font-weight: 500;
                    text-align: center;

                    &::after {
                        content: '';
                        display: block;
                        width: 50px;
                        height: 2px;
                        background-color: #673bf9;
                        margin: 5px auto;
                    }
                }

                .notes-body {
                    .notes-list {
                        height: 340px;
                        overflow-y: auto;
                        scrollbar-width: none;
                        -ms-overflow-style: none;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        padding: 5px 0;


                        @media (max-width: 1280px) {
                            height: 100%;
                        }

                        .note-description {
                            display: flex;
                            flex-direction: column;
                            align-items: start;
                            margin: 0;
                            padding: 5px;
                            border: 1px solid #f5f5f5;
                            margin-bottom: 10px;
                            border-radius: 10px;

                            h3 {
                                margin: 5px 0;
                                padding: 0;
                                font-size: 1rem;
                                font-weight: 500;
                                color: #848484;

                                span {
                                    font-size: 1rem;
                                    font-weight: 400;
                                    color: #3d394c;
                                }
                            }

                            .note-quill {
                                width: 100%;
                                background-color: #edf7ff;
                                border-radius: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.home-courses-dashboard {
    .course-card {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 40px;
        transition: all 0.5s ease-in-out;
        // height: 400px;
        cursor: pointer;

        @media (max-width: 1280px) {
            height: 100%;
        }

        &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            transition: all 0.5s ease-in-out;
            transform: scale(1.01);
        }

        .card-image-container {
            position: relative;
            overflow: hidden;

            .cource-card-header-buttons {
                position: absolute;
                bottom: 10px;
                right: 10px;
                display: flex;
                justify-content: space-between;
                width: 98%;

                .duration {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    height: 32px;
                    background: #5a4992;
                    gap: 10px;
                    padding: 0 10px;
                }

                .btns {
                    display: flex;
                    gap: 10px;
                }
            }

            .course-visiblity {
                position: absolute;
                top: 10px;
                right: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                height: 32px;
                gap: 10px;
                border-radius: 50px;
                padding: 5px 10px;
                font-size: 0.8rem;
            }

            img {
                display: block;
                width: 100%;
                object-fit: cover;
                transition: all 0.5s ease-in-out;

                &:hover {
                    transform: scale(1.1);
                    transition: all 0.5s ease-in-out;
                }
            }
        }

        .card-body {
            padding: 10px;
            gap: 10px;

            .card-description {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .price {
                    p {
                        margin: 5px 0;
                        font-size: 1.2rem;
                        font-weight: 500;
                    }

                    .free-text {
                        color: #005522;
                        background-color: #a5ffd5;
                        padding: 0px 20px;
                        border-radius: 50px;
                    }
                }

                .author {
                    display: flex;
                    gap: 5px;
                    align-items: center;

                    img {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                h3 {
                    font-size: 0.9rem;
                    margin: 0;
                    font-weight: 500;
                    line-height: 1;
                    color: rgb(73, 73, 73);
                }
            }

            .title {
                font-size: 1.1rem;
                font-weight: 500;
                margin: 10px 0;
                color: rgb(37, 37, 37);
                height: 60px;
            }
        }

        .card-footer {
            width: 100%;
            padding: 10px;

            .button-continer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                gap: 5px;

                .enroll-now {
                    width: 100%;
                    // padding: 0 10px;

                    .ant-btn {
                        width: 100%;
                        border: #1677ff solid 1px;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}