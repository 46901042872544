@import '../../../../../theme/variables.scss';

.ant-layout-sider {
    position: fixed !important;
    height: 100vh;
    width: $menu-slider-width !important;
    min-width: none !important;
    max-width: none !important;
    background: linear-gradient(180deg, #245ca0 0%, #1a2f6f 100%) !important;

    @media (max-width: 1280px) {
        width: $menu-slider-width-ss !important;
    }

    @media (max-width: 768px) {
        width: 0 !important;
        z-index: 1000;
    }


    .header-logo-vertical {
        width: 150px;
        margin: 10px auto 0 auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
        }
    }

    .ant-menu-item {
        line-height: 50px;
        font-weight: 500;
        color: #fff !important;
        padding-left: 20px;
        transition: all 0.3s ease-in-out;
        border-radius: 50px;
        width: calc(100% - 30px);
        margin: 10px 15px;

        &:hover {
            color: #b9d8ff !important;
        }

        &.ant-menu-item-selected {
            color: $primary-color !important;
            background: $light-primary-color;
        }
    }
}


.ant-layout .ant-layout-sider-zero-width-trigger {
    top: 7px;
    background: #1a2f6f;
    right: -45px;
    border-radius: 6px !important;
}

.ant-layout .ant-layout-sider-zero-width-trigger::after {
    background: none !important;
}