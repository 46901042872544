.submit-assessment {
    p {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 1.5;
        color: #5f5f5f;

        span {
            font-weight: 600;
            color: red;
        }
    }

    h3 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;

    }

    h4 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .attempt-data {
        h3 {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 20px;
            color: #5f5f5f;
            display: flex;

            .lable {
                width: 200px;
            }

            .value {
                font-weight: 400;
                color: #000;

                a {
                    color: #9f51d3;
                    text-decoration: none;
                }
            }
        }
    }
}