.payments {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .actions {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}