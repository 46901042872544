.foget-password-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;

    .form {
        width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        overflow: hidden;

        @media (max-width: 768px) {
            width: 90%;
        }

        .signin-top {
            width: 100%;
            // padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;

            h1 {
                font-size: 2.5rem;
                font-weight: 700;
                color: #ebebeb;
                text-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }

            h2 {
                font-size: 1.5rem;
                font-weight: 400;
                color: #eeeeee;
                text-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
        }

        .ant-spin-nested-loading {
            width: 100%;

            .ant-form {
                margin: 40px;

                @media (max-width: 768px) {
                    margin: 20px;
                }

                .fogot-button {
                    width: fit-content;
                }

                .form-button {
                    width: 100%;
                    height: 40px;
                    font-size: 16px;
                    font-weight: 500;
                    border-radius: 50px;
                    margin-top: 10px;
                }

                .sign-up {
                    text-align: center;
                    margin-top: 20px;
                }
            }
        }
    }

    .instruction {
        width: 85%;
        margin: 20px auto 0 auto;
    }
}